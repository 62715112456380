import {on} from 'delegated-events'

on('submit', '.js-remove-org-form', event => {
  const iAgree = document.querySelector<HTMLInputElement>('.js-confirm-remove-org-checkbox')!
  const errorMessage = document.querySelector<HTMLElement>('.js-confirm-removal-error')

  if (!iAgree.checked) {
    event.preventDefault()

    iAgree.setAttribute('aria-invalid', 'true')
    iAgree.setAttribute('invalid', 'true')
    iAgree.setAttribute('aria-describedby', 'js-confirm-removal-error')
    errorMessage!.hidden = false
    errorMessage!.setAttribute('aria-hidden', 'false')
    errorMessage!.scrollIntoView({
      behavior: 'smooth',
    })
  }
})

on('click', '.js-confirm-remove-org-checkbox', event => {
  const iAgree = event.target as HTMLInputElement
  const errorMessage = document.querySelector<HTMLElement>('.js-confirm-removal-error')

  if (iAgree.checked) {
    iAgree.removeAttribute('aria-invalid')
    iAgree.removeAttribute('invalid')
    iAgree.removeAttribute('aria-describedby')
    errorMessage!.hidden = true
    errorMessage!.setAttribute('aria-hidden', 'true')
  }
})
